import DepthControl from './services/depth.js'

const LAYER_1_ITEMS = [
  { x: { min: 42, max: 45 }, y: { min: 73, max: 76 }},
  { x: { min: 88, max: 98 }, y: { min: 60, max: 70 }},
  { x: { min: 15, max: 18 }, y: { min: 80, max: 82 }},
  { x: { min: 60, max: 65 }, y: { min: 75, max: 85 }},
  { x: { min: -10, max: -2 }, y: { min: 55, max: 58 }}
]

const LAYER_2_ITEMS = [
  { x: { min: 8, max: 10 }, y: { min: 25, max: 28 }},
  { x: { min: 79, max: 80 }, y: { min: 10, max: 15 }},
  { x: { min: 85, max: 87 }, y: { min: 8, max: 12 }}
]

const LAYER_3_ITEMS = [
  { x: { min: 48, max: 50 }, y: { min: 72, max: 75 }},
  { x: { min: 18, max: 20 }, y: { min: 73, max: 75 }},
  { x: { min: 24, max: 26 }, y: { min: 72, max: 75 }},
  { x: { min: 64, max: 65 }, y: { min: 73, max: 76 }},
  { x: { min: 70, max: 73 }, y: { min: 73, max: 76 }},
  { x: { min: -2, max: 5 }, y: { min: 73, max: 76 }},
  { x: { min: 88, max: 93 }, y: { min: 75, max: 77 }}
]

function renderLayerItems (itemsArray = [], layerId = '', itemClassPrefix = '') {
  if (!itemsArray.length || !layerId || !itemClassPrefix) { return }
  for (let i = 0; i < itemsArray.length; i++) {
    const item = document.querySelector(`#${layerId} .${itemClassPrefix}-${i + 1}`)
    if (!item) { return }

    const x = Math.random() * (itemsArray[i].x.max - itemsArray[i].x.min) + itemsArray[i].x.min
    const y = Math.random() * (itemsArray[i].y.max - itemsArray[i].y.min) + itemsArray[i].y.min

    item.style.left = `${x}%`
    item.style.top = `${y}%`
  }

  const layer = document.querySelector(`#${layerId}`)
  layer.classList.add('rendered')
}

renderLayerItems(LAYER_1_ITEMS, 'layer-1', 'item')
renderLayerItems(LAYER_2_ITEMS, 'layer-2', 'top')
renderLayerItems(LAYER_3_ITEMS, 'layer-3', 'item')

const depthControl = new DepthControl()
depthControl.init()

// const logo = document.querySelector('#logo')
// logo.addEventListener('click', () => {
//   const header = document.querySelector('header')
//   header.classList.toggle('active')
//   // buttonWrapper = document.querySelector('header .button-wrapper')
// })
