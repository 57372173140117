const LAYER_1_X_DELTA = 1
const LAYER_1_Y_DELTA = 1.5
const LAYER_2_X_DELTA = 1.5
const LAYER_2_Y_DELTA = 2
const LAYER_3_X_DELTA = 4
const LAYER_3_Y_DELTA = 3

export class DepthControl {
  constructor () {
    this.moveLisenter = null
    this.initialized = false
    this.isMoving = false
  }

  init () {
    if (this.initialized) { return }
    this.moveLisenter = document.addEventListener('mousemove', this.onMouseMove.bind(this))
    this.initialized = true
  }

  onMouseMove (e) {
    if (!this.isMoving) {
      window.requestAnimationFrame(() => {
        const layer1 = document.querySelector('#layer-1')
        const layer2 = document.querySelector('#layer-2')
        const layer3 = document.querySelector('#layer-3')

        const x = e.clientX
        const y = e.clientY
        const middleX = window.innerWidth / 2
        const middleY = window.innerHeight / 2
        const onLeft = x < middleX
        const onTop = y < middleY
        const xDelta = (onLeft ? -1 : 1) * Math.abs(x - middleX) / middleX
        const yDelta = (onTop ? -1 : 1) * Math.abs(y - middleY) / middleY

        if (layer1) {
          layer1.style.transform = `translate(${(xDelta * LAYER_1_X_DELTA).toFixed(2)}%, ${(yDelta * LAYER_1_Y_DELTA).toFixed(2)}%)`
        }
        if (layer2) {
          layer2.style.transform = `translate(${(xDelta * LAYER_2_X_DELTA).toFixed(2)}%, ${(yDelta * LAYER_2_Y_DELTA).toFixed(2)}%)`
        }
        if (layer3) {
          layer3.style.transform = `translate(${(xDelta * LAYER_3_X_DELTA).toFixed(2)}%, ${(yDelta * LAYER_3_Y_DELTA).toFixed(2)}%)`
        }
        this.isMoving = false
      })
    }
    this.isMoving = true
  }
}

export default DepthControl;
